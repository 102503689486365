import React, { useEffect, useState } from 'react';
import JsonData from '../data/notification.json';
//import JsonData from 'http://localhost:3000/data/notification.json';

function Json() {

  const [json, setJsonObj] = useState('');
  //const [loadState, setLoadState] = useState(false);
  //

  useEffect(() => {
    //console.log('before json = '+ json);
    getMoviesFromApi();
    console.log('after json = ' + json);
    //console.log('loadState= ' + loadState);
  }, [])
  //const data = String(JsonData);
  const jsonUrl = window.location.origin + '/data/notification.json'
  console.log('jsonUrl = ' + jsonUrl)
  const data = json.notification;

  return (
    <div>
      <table>
        <tr>
          <td>id</td><td>タイトル</td><td>内容</td><td>更新日</td>
        </tr>
        {
            data && (
            data.map((item, index) => {
              return (
                <tr key={index}>
                  <td>{item.notification_id}</td>
                  <td>{item.notification_title}</td>
                  <td>{item.notification_content}</td>
                  <td>{item.notification_update_date}</td>
                </tr>
              )
            })
          )
        }
        { /*<PrettyPrint jsonObj={json.notification} /> */}
      </table>
    </div>
  )

  async function getMoviesFromApi() {
    let responseJson
    let headers = new Headers();
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');
    headers.append('Origin', 'http://localhost:3000');

    try {
      let response = await fetch(jsonUrl);
      //response.set('Access-Control-Allow-Origin', '*');
      responseJson = await response.json();
      console.log('responseJson = ' + responseJson);
      console.log('JsonData = ' + JsonData);
      //setJsonObj(responseJson);
      setJsonObj(responseJson);
      console.log('getMoviesFromApi()内のjson = ' + json);
      //setLoadState(true);
      //return responseJson;
    } catch (error) {
      console.error('jsonエラー' + error);
    }
    //setJsonObj(responseJson);
  }

}

function PrettyPrint(props) {
  return <code>{JSON.stringify(props.jsonObj, null, 2)}</code>
}



export default Json;