//サーバー関連　　
export const INTERVAL_TIME_FOR_ACCESS_TO_SERVER = 24; //この設定は重要！　サーバーへのアクセス時間間隔。時間で指定。24=24時間間隔

//空データ
export const DATA_EMPTY = 0;  //空データの取得

//エラー関連
export const DATA_ACQUISITION_ERR = -1;  //データ取得エラー

//成功関連
export const DATA_ACQUISITION_SUCCESS = 1; //データ取得成功

//firestore コレクション名
export const NOTIFICATIONS = 'notifications';

//firestore notificationsコレクションのドキュメントのフィールド名
export const NOTIFICATION_ID = 'id';
export const NOTIFICATION_title = 'title';
export const NOTIFICATION_CONTENT = 'content';
export const NOTIFICATION_LINK = 'link';
export const NOTIFICATION_TYPE_ID = 'typeId';
export const NOTIFICATION_UPDATE_DATE = 'updateDate';
export const NOTIFICATION_REGISTRATION_DATE = 'registrationDate';

//データ並べ替え順
export const ASC = 'asc';    //昇順
export const DESC = 'desc';  //降順

//新着情報として表示する最大数
export const MAX_NEW_INFORMATION_LENGTH = 5;

//更新履歴の1ページに表示するリスト数
export const HISTORY_LIST_COUNT_PER_ONE_PAGE = 10;

//URL
export const TERMS_OF_SERVICE_JSON_URL = window.location.origin + '/data/terms_of_service.json'
export const PRIVACY_POLICY_JSON_URL = window.location.origin + '/data/privacy_policy.json'

//画像のパス
export const PUBLIC_IMG_INTRODUCTION_PATH = window.location.origin + '/data/images/introduction/';

//ウェブサイト発行年
export const YEAR_OF_PUBLICATION_FOR_WEB_SITE = 2021;