import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import * as Constants from '../constants'

function TermsOfServiceDisplay() {

    const [jsonData, setJsonObj] = useState('');
    //const [loadState, setLoadState] = useState(false);

    useEffect(() => {
        //console.log('before json = '+ json);
        getTermsOfServiceFromApi();
        console.log('jsonData = ' + jsonData);
    }, [])

    const data = jsonData;
    const dataSection = jsonData.section;

    const jsonUrl = window.location.origin + '/data/terms_of_service.json'
    console.log('jsonData = ' + jsonData);
    console.log('dataSection = ' + dataSection)
    return (
        <>
            <Container className='TermsOfServiceDisplay'>
                {
                    data && (
                        <section>
                            <h2>{data.title}</h2>
                            <p>{data.premise}</p>
                        </section>
                    )
                }

                {
                    dataSection && (
                        dataSection.map((item, index) => {
                            return (
                                <section key={index}>
                                    <h3>{item.number}. {item.title}</h3>
                                    {
                                        item.content && (
                                            <p>{item.content}</p>
                                        )
                                    }
                                    {
                                        item.sub_section && (

                                            item.sub_section.length == 1 ?

                                                //sub_sectionの内容が1つしかない時

                                                item.sub_section.map((subItem, subIndex) =>

                                                    <p key={subIndex}>{subItem.content}</p>
                                                )

                                                : 
                                                
                                                //sub_sectionの内容が2以上の時リスト表示

                                                <ol>{
                                                    item.sub_section.map((subItem, subIndex) =>

                                                        <li key={subIndex}>{subItem.content}</li>
                                                    )
                                                }
                                                </ol>


                                        )
                                    }
                                </section>
                            )
                        })
                    )
                }

                {
                    data && (
                        <section>
                                <h3>制定日・改定日</h3>
                                <p>制定日：{data.date_of_enforcement}</p>
                                <p>改定日：</p>
                                {
                                    data.date_of_revision_list && (
                                        <ul>
                                            {
                                                data.date_of_revision_list.map((revisionListItem, revisionListIndex) => {
                                                    return (
                                                        <li key={revisionListIndex}>{revisionListItem.date}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                }
                            </section>
                    )
                }
            </Container>
        </>
    )

    async function getTermsOfServiceFromApi() {

        try {
            console.log('getTermsOfServiceFromApiが呼ばれた');
            let response = await fetch(Constants.TERMS_OF_SERVICE_JSON_URL);
            console.log('getTermsOfServiceFromApi内のfetchの直後');
            //let response = await fetch(jsonUrl);
            console.log('URL = ' + Constants.TERMS_OF_SERVICE_JSON_URL);
            let responseJson = await response.json();
            setJsonObj(responseJson);
            //console.log('getMoviesFromApi()内のjson = ' + json);

        } catch (error) {
            console.error('jsonエラー : ' + error);
        }

    }

}

export default TermsOfServiceDisplay;