export const SET_NOTIFICATION_DATA = 'SET_NOTIFICATION_DATA';

export const setNotificationData = (notificationData) => {
    return {
        type: 'SET_NOTIFICATION_DATA',
        payload: {
            dateOfAccessToServer: notificationData.dateOfAccessToServer, //データがあるサーバーにアクセスした日時
            data: notificationData.data                  //取得したデータ
        }
    }
}