import * as Actions from './actions';
import initialState from '../store/initialState'

export const notificationsReducer = (state = initialState.notifications, action) => {
    switch(action.type) {
        case Actions.SET_NOTIFICATION_DATA:
            return {
                ...state,
                ...action.payload
            }
            default:
                return state
    }
}