import React from 'react';
import { Switch, Route } from 'react-router-dom';
import TopPage from '../templates/top/TopPage';
import TermsOfService from '../templates/service/TermsOfService';
import PrivacePolicy from '../templates/privacy/PrivacyPolicy';
import History from '../templates/history/History';
import Contact from '../templates/contact/Contact';
import HistoryDetail from '../templates/history/HistoryDetail';
import Json from '../components/Json';
import Introduction from '../templates/introduction/Introduction';


function Router(){
    return(
        <Switch>
            <Route path='/' exact component={TopPage} />
            <Route path='/service' exact component={TermsOfService} />
            <Route path='/privacy' exact component={PrivacePolicy} />
            <Route path='/history' exact component={History} />
            <Route path='/contact' exact component={Contact} />
            <Route path='/json' exact component={Json} />
            <Route path='/introduction/:id' exact component={Introduction} />
        </Switch>

    )
}

export default Router;