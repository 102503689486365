//更新履歴
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import HistoryList from '../../components/HistoryList';

function History() {
    document.title = "更新履歴"
    return (
        <>
            <HistoryList />
        </>
    )
}

export default History;