import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DateConverter from '../libraries/dateConverter';
import * as Constants from '../constants'
import { getNotificationData } from '../redux/notifications/operations'
import { Container, Row, Col, Table } from 'react-bootstrap';

function NewInformation() {

    const dispatch = useDispatch();

    //重要：useEffectはreturnが返された後に実行される。
    //return内で、useEffectで取得したデータを使うとエラーになる場合がある。
    //return内でデータの状況に応じた分岐が必要になる。
    useEffect(() => {
        console.log('NewInformationのuseEffectが呼ばれた');
        //ページ読み込み完了時に新着情報を取得する
        dispatch(getNotificationData());
    }, [])

    const selector = useSelector((state) => state);
    const data = selector.notifications.data;
    console.log('notifications = ' + selector.notifications.data);
    console.log(data);

    return (

        <Container className='NewInformation'>
            <Row>
                <h2>新着情報</h2>
            </Row>
            <Row>
                <Col>
                    {  //dataがある場合は以下を実行
                        data && (
                            <Table>
                                {

                                    data.slice(0, Constants.MAX_NEW_INFORMATION_LENGTH).map((item, i) => {
                                        //console.log('i = ' + i)
                                        return (

                                            <tr key={i}>
                                                <td>{item.updateDate}</td><td>{item.content}</td>
                                            </tr>

                                        )
                                    })
                                }
                            </Table>
                        )
                    }

                    {   //dataがない場合は以下を実行
                        !data && (
                            <p className='NewInformationNoData'>お知らせはまだありません。</p>
                        )
                    }
                </Col>
            </Row>
        </Container>

    )

}

export default NewInformation;