import React, { Component } from "react";
import Slider from "react-slick";
import { Container } from 'react-bootstrap';
import img_w992up_1 from '../images/jumbotron/top_w992up_1.png'
import img_w992up_2 from '../images/jumbotron/top_w992up_2.png'
import img_w992up_3 from '../images/jumbotron/top_w992up_3.png'
import img_w992up_4 from '../images/jumbotron/top_w992up_4.png'
import img_w992up_5 from '../images/jumbotron/top_w992up_5.png'
//import img1 from '../images/jumbotron/beach-5795560_640.jpg'
//import img2 from '../images/jumbotron/clouds-5784152_640.jpg'
//import img3 from '../images/jumbotron/lighthouse-5454155_640.jpg'
//import img4 from '../images/jumbotron/mountains-5778105_640.jpg'
//import img5 from '../images/jumbotron/sunset-5800386_640.jpg'
import img_w576up992down_1 from '../images/jumbotron/top_w576up992down_1.png'
import img_w576up992down_2 from '../images/jumbotron/top_w576up992down_2.png'
import img_w576up992down_3 from '../images/jumbotron/top_w576up992down_3.png'
import img_w576up992down_4 from '../images/jumbotron/top_w576up992down_4.png'
import img_w576up992down_5 from '../images/jumbotron/top_w576up992down_5.png'

import img_w576down_1 from '../images/jumbotron/top_w576down_1.png'
import img_w576down_2 from '../images/jumbotron/top_w576down_2.png'
import img_w576down_3 from '../images/jumbotron/top_w576down_3.png'
import img_w576down_4 from '../images/jumbotron/top_w576down_4.png'
import img_w576down_5 from '../images/jumbotron/top_w576down_5.png'

export default class SlickAppjumbotron extends Component {
  render() {
    const settings = {
      arrows: true,
      dots: true,
      infinite: true,
      speed: 500,
      autoplaySpeed: 6000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      fade: true,
      pauseOnHover: true
    };
    return (
      <Container>
        <Slider {...settings} >
          <div style="text-align : center"> 
            <img className="mx-auto ImgW992Up" src={img_w992up_1} />
            <img className="mx-auto ImgW576Up992Down" src={img_w576up992down_1} />
            <img className="mx-auto ImgW576Down" src={img_w576down_1} />
          </div>
          <div>
            <img className="mx-auto ImgW992Up" src={img_w992up_2} />
            <img className="mx-auto ImgW576Up992Down" src={img_w576up992down_2} />
            <img className="mx-auto ImgW576Down" src={img_w576down_2} />
          </div>
          <div>
            <img className="mx-auto ImgW992Up" src={img_w992up_3} />
            <img className="mx-auto ImgW576Up992Down" src={img_w576up992down_3} />
            <img className="mx-auto ImgW576Down" src={img_w576down_3} />
          </div>
          <div>
            <img className="mx-auto ImgW992Up" src={img_w992up_4} />
            <img className="mx-auto ImgW576Up992Down" src={img_w576up992down_4} />
            <img className="mx-auto ImgW576Down" src={img_w576down_4} />
          </div>
          <div>
            <img className="mx-auto ImgW992Up" src={img_w992up_5} />
            <img className="mx-auto ImgW576Up992Down" src={img_w576up992down_5} />
            <img className="mx-auto ImgW576Down" src={img_w576down_5} />
          </div>
        </Slider>
      </Container>
    );
  }
}