//お問合せ
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import ContactForm from '../../components/ContactForm'

function Contact() {
    document.title = "お問合せ"
    return (
        <Container>
            <Row>
                <Col>
                   <ContactForm />
                </Col>
            </Row>
        </Container>
 
    )
}

export default Contact;