//トップページ
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import NewInformation from '../../components/NewInformation';
import SlickAppIntro from '../../components/SlickAppIntro';
import ContentIntroduction from '../../components/ContentIntroduction';

function TopPage() {
    document.title = "トップ"
    return (
        <>
            <Container className="SlickAppIntro-wrap" fluid>
                <Row>
                    <Col>
                        <SlickAppIntro />
                    </Col>
                </Row>
            </Container>
            <ContentIntroduction />
            <NewInformation />
        </>
    )
}

export default TopPage;