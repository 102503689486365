import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as DateConverter from '../libraries/dateConverter';
import * as Constants from '../constants'
import { getNotificationData } from '../redux/notifications/operations'
import { Container, Row, Col, Card } from 'react-bootstrap';
import Pagination from '@material-ui/lab/Pagination';

function HistoryList() {
    const dispatch = useDispatch();
    const [pageNum, setPageNum] = useState(1);

    //重要：useEffectはreturnが返された後に実行される。
    //return内で、useEffectで取得したデータを使うとエラーになる場合がある。
    //return内でデータの状況に応じた分岐が必要になる。
    useEffect(() => {
        console.log('HistoryListのuseEffectが呼ばれた');
        //ページ読み込み完了時に新着情報を取得する
        dispatch(getNotificationData());
        window.scroll(0, 0);//pageNumClick関数内で実行するとページレンダー前に呼ばれるので上手く動作しない。
    }, [pageNum])

    const selector = useSelector((state) => state);
    const dateOfAccessToServer = selector.notifications.dateOfAccessToServer;
    const data = selector.notifications.data;
    console.log('dateOfAccessToServer = ' + dateOfAccessToServer);
    console.log('notifications = ' + data);
    console.log('dataの長さ=' + data.length);


    //最大ページ数を求める
    let pageMaxNumber;
    if ((data.length % Constants.HISTORY_LIST_COUNT_PER_ONE_PAGE) == 0) {
        pageMaxNumber = Math.floor(data.length / Constants.HISTORY_LIST_COUNT_PER_ONE_PAGE);
    } else {
        pageMaxNumber = Math.floor(data.length / Constants.HISTORY_LIST_COUNT_PER_ONE_PAGE) + 1;
    }

    //ページ番号を元に最初と最後に表示するリストの番号を設定
    const pageFirstListNum = (pageNum - 1) * (Constants.HISTORY_LIST_COUNT_PER_ONE_PAGE);
    let pageLastListNum;
    if (pageNum == pageMaxNumber) {
        pageLastListNum = data.length;
    } else {
        pageLastListNum = pageNum * Constants.HISTORY_LIST_COUNT_PER_ONE_PAGE;
    }

    //ページ番号をクリックした時に呼び出される関数
    const pageNumClick = (page) => {
        setPageNum(page);
        //window.scrollTo(0, 1000)
        //window.scroll(0, 0);
        //window.scrollTop();
    
        //document.getElementById("root").scrollTo(0, 0);
    }

    console.log('pageFirstNum = ' + pageFirstListNum);
    console.log('pageLastNum = ' + pageLastListNum);

    return (
        <>
            <Container className='HistoryList'>

                <Row>
                    <Col>
                        {  //dataがある場合は以下を実行
                            data && (
                                data.slice(pageFirstListNum, pageLastListNum).map((item, i) => {
                                    //console.log('i = ' + i)
                                    return (

                                        <Card key={i} className='HistoryListCard'>

                                            <Card.Body className='HistoryListCardBody'>
                                                <Card.Title className='HistoryListCardTitle'>{item.id}. {item.title}</Card.Title>
                                                <Card.Text>
                                                    {item.content}
                                                </Card.Text>
                                            </Card.Body>
                                            <Card.Footer className='HistoryListCardFooter'>
                                                <span className="text-muted">更新日: {item.updateDate}</span>&nbsp;&nbsp;&nbsp;&nbsp;<span className="text-muted">登録日: {item.registrationDate}</span>
                                            </Card.Footer>
                                        </Card>

                                    )
                                })
                            )
                        }

                        {   //dataがない場合は以下を実行
                            !data && (
                                <p className='HistoryListNoData'>お知らせはまだありません。</p>
                            )
                        }
                    </Col>
                </Row>

                {   //dataがある場合は以下を実行
                    data && (
                        <Row className='HistoryListPagination'>
                            <Col className='d-flex justify-content-center'>
                                <Pagination count={pageMaxNumber} onChange={(e, page) => setPageNum(page)} variant="outlined" shape="rounded" page={pageNum} />
                            </Col>

                        </Row>
                    )
                }
            </Container>
        </>
    )
}

export default HistoryList;