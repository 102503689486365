import { setNotificationData } from './actions'
import * as DateConverter from '../../libraries/dateConverter'
import * as Constants from '../../constants'
import '../../firebase'
import { db } from '../../firebase';

export const getNotificationData = () => {
    return async (dispach, getState) => {
        const state = getState();

        const dateOfAccessToServer = state.notifications.dateOfAccessToServer;

        const dt = new Date();
        const today = DateConverter.getFormatDate(dt) + ' ' + DateConverter.getFormatTime(dt);
        const dToday = new Date(today);
        const dBefore = new Date(dateOfAccessToServer);

        const diffTime = dToday.getTime() - dBefore.getTime();
        //const diffDay = Math.floor(diffTime / (1000 * 60 * 60 * 24));日差
        const diffHour = Math.floor(diffTime / (1000 * 60 * 60));//時差
        console.log('diffHour = '+ diffHour);
        console.log('this is from operations dateOfAccessToServer = ' + dateOfAccessToServer);
        //dateOfAccessToServerが空か以前アクセスした時間からINTERVAL_TIME_FOR_ACCESS_TO_SERVER時間過ぎた時notificationデータを取得してnotificationsを更新する
        //if(true){
        if (!dateOfAccessToServer || diffHour > Constants.INTERVAL_TIME_FOR_ACCESS_TO_SERVER) {

            //notificationデータの取得
            let notifications = [];
            const notificationsRef = db.collection('notifications');
            const query = await notificationsRef.orderBy('id', 'desc').get()
                .then(snapshot => {

                    if (snapshot.empty) {
                        console.log('No matching documents.');
                        return;
                    }
                    console.log('snapshot = ' + snapshot.docs);
                    console.log('snapshotのサイズ = ' + snapshot.size);
                    
                    snapshot.forEach(doc => {

                        let notification = [];
                        notification = {
                            firebaseDocId: doc.id,
                            id: doc.data().id,
                            title: doc.data().title,
                            content: doc.data().content,
                            link: doc.data().link,
                            typeId: doc.data.typeId,
                            registrationDate: doc.data().registrationDate,
                            updateDate: doc.data().updateDate
                        }
                        notifications.push(notification);

                        console.log(doc.id, '=>', doc.data());
                    });
                     console.log('notifications => '+ notifications);
                })
                .catch(err => {
                    console.log('Error getting documents', err);
                });

            //notificationsの更新
            dispach(setNotificationData({
                dateOfAccessToServer: today,
                data: notifications
            }))
        }
    }
}