import React from 'react';

// let count = 0;
// const incrementFuc = () => {
//   count++;
//   document.getElementById('btn').innerText = count;
//   console.log(count)
// }


function Test(prop) {

  const [isChecked, isCheckedFunc] = React.useState(true);
  const [count, countUp] = React.useState(0);

  const incrementFuc = () => {
    countUp(count + 1)
  }

  React.useEffect(() => {
      console.log('Testが呼ばれた')
      return () => {
        console.log('Testが呼ばれた2')
      }
  }, [isChecked])

  // React.useEffect(() => {
  //   document.getElementById('btn').addEventListener('click', incrementFuc)
  //   return () => {
  //     document.getElementById('btn').removeEventListener('click', incrementFuc)
  //   }
  // })
  
    //console.log('Testが呼び出された');
    return (
      <div>
        <input type='checkbox' defaultChecked={isChecked} onClick={() => isCheckedFunc(!isChecked)}/>
        <input type='text' defaultValue='あいう'/>
        <button id='btn' onClick={() => incrementFuc()}>いいね{count}</button>
        <p>{prop.data}</p>
      </div>
    );
  }
  
  export default Test;