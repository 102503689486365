//機能紹介詳細
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import ContentIntroductionDetail from '../../components/ContentIntroductionDetail';

function Introduction({match}) {
    console.log("match="+match.params.id)
    return (
        <>
            <ContentIntroductionDetail id={match.params.id}/>
        </>
    )
}

export default Introduction;