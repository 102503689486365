import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from "react-router-dom";
import * as Constants from '../constants'
import jsonData from '../data/introduction.json';

function Footer() {

    const data = jsonData.introductions;
    const thisYear = new Date().getFullYear();
    let year;

    if (Constants.YEAR_OF_PUBLICATION_FOR_WEB_SITE == thisYear) {
        year = thisYear;
    } else {
        year = Constants.YEAR_OF_PUBLICATION_FOR_WEB_SITE + ' - ' + thisYear;
    }

    return (
        <footer>
            <Container className="Footer">
                <Row>
                    <Col className='FooterLinkCol' sm>
                        <h2>サイトナビ</h2>
                        <ul>
                            <li><Link to={'/'} className='FooterLink'>トップ</Link></li>
                            <li><Link to={'/service'} className='FooterLink'>利用規約</Link></li>
                            <li><Link to={'/privacy'} className='FooterLink'>プライバシーポリシー</Link></li>
                            <li><Link to={'/history'} className='FooterLink'>更新履歴</Link></li>
                            <li><Link to={'/contact'} className='FooterLink'>お問合せ</Link></li>
                        </ul>
                    </Col>
                    <Col className='FooterLinkCol' sm>
                        <h2>主な機能</h2>
                        <ul>
                            {
                                data && (
                                    data.map((item, index) => {
                                        return (
                                            <li><Link to={'/introduction/'+item.number} className='FooterLink'>{item.title}</Link></li>
                                        )
                                    })
                                )
                            }
                        </ul>
                    </Col>
                </Row>
                <Row className='FooterCopyRightRow'>
                    <Col><p className='FooterCopyRight'>&copy; {year} arbalan</p></Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;