//更新履歴詳細
import React from 'react';

function NotificationDetail() {
    return (
        <div>
            <h2>更新履歴詳細のページ</h2>
        </div>
    )
}

export default NotificationDetail;