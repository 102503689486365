export const PAGE_CHANGED = 'PAGE_CHANGED';

export const pageChanged = (pageState) => {
    return {
        type: 'PAGE_CHANGED',
        payload: {
            currentPageNum: pageState.currentPageNum,
            pageTitleName: pageState.pageTitleName,
            pageUrl: pageState.pageUrl
        }
    }
}