const initialState = {
    pages: {
        currentPageNum: 0,       //現在のページ番号。ナビゲーションバーの0を起点とした左からの数値
        pageTitleName: 'トップ', //ページのタイトル名
        pageUrl: '/'             //ページのURL
    },
    notifications: {
        dateOfAccessToServer: '', //サーバーにアクセスした日時
        data: ''                  //取得したデータ
    }
}

export default initialState;