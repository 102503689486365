// 日付をyyyy/mm/dd 形式で返す
export const getFormatDate = (dt) => {
    
    var y = dt.getFullYear();
    var m = dt.getMonth() + 1;
    var d = dt.getDate();
    
    if (m < 10) m = '0' + m;

    if (d < 10) d = '0' + d;

    var ymd = y + '/' + m + '/' + d;
    
    return ymd;
    
}

// 時刻をhh:mm:ss 形式で返す
export const getFormatTime = (dt) => {
    
    var h = dt.getHours();
    var m = dt.getMinutes();
    var s = dt.getSeconds();
    
    if (h < 10) h = '0' + h;
    
    if (m < 10) m = '0' + m;
    
    if (s < 10) s = '0' + s;
    
    var hms = h + ':' + m + ':' + s;
    
    return hms;
    
}