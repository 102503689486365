import {createStore as reduxCreateStore,
        combineReducers,
        compose,
        applyMiddleware
    } from 'redux';
import {pagesReducer} from '../pages/reducers';
import {notificationsReducer} from '../notifications/reducers';
import persistState from "redux-localstorage";
import thunk from "redux-thunk";//非同期処理で使う

export default function createStore(){
    return compose(persistState())(reduxCreateStore) (//composeを使うことで永続的にstateを保存できる。
        combineReducers ({
            pages: pagesReducer,
            notifications: notificationsReducer
        }),
        applyMiddleware(
            thunk
        )
    )
}
