//利用規約
import React, { useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import Navigation from '../../components/Navigation'
import TermsOfServiceDisplay from '../../components/TermsOfServiceDisplay'

function TermsOfService() {

    //console.log(Navigation.myRef.current.children.length);
    document.title = "利用規約"
    return (
        <Container>
            <Row>
                <Col>
                    <TermsOfServiceDisplay />
                </Col>
            </Row>
        </Container>
    )
}

export default TermsOfService;