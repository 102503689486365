import React from "react";
import { Container, Row, Col, Card } from 'react-bootstrap'
import jsonData from '../data/introduction.json';
import fixed_balance_1 from '../images/introduction/fixed_balance_1.png'
import img_2 from '../images/introduction/variable_income_2.png'
import img_3 from '../images/introduction/variable_expenses_3.png'
import img_4 from '../images/introduction/graph_4.png'
import img_5 from '../images/introduction/search_5.png'
import img_6 from '../images/introduction/data_backup_6.png'
import img_7 from '../images/introduction/file_export_7.png'
import img_8 from '../images/introduction/balance_setting_8.png'
import img_9 from '../images/introduction/notification_9.png'
import { Link } from "react-router-dom";
import * as Constants from '../constants'

function ContentIntroduction() {

    const data = jsonData.introductions;
    console.log('introduction.json = ' + data);
    console.log('introduction.jsonの長さ = ' + data.length);
    console.log('introduction.json = ' + jsonData.test);
    const imgUrl = 'img_';

    return (
        <>
            <Container className='ContentIntroduction'>
                <Row>
                    <h2>主な機能のご紹介</h2>
                </Row>
                <Row className="ContentIntroductionRow">
                    {
                        data && (
                            data.map((item, index) => {
                                return (
                                    <Col className="p-2 col-auto">
                                        <Link to={'/introduction/'+item.number} className='ContentIntroductionLink'>
                                            <Card key={index} style={{ width: '188px' }} className="ContentIntroductionCard">
                                                <Card.Img variant='top' src={Constants.PUBLIC_IMG_INTRODUCTION_PATH + item.img_url} />
                                                <Card.Body>
                                                    <Card.Title>{item.title}</Card.Title>
                                                    <Card.Text style={{ textAlign: 'left' }}>
                                                        {item.summary}
                                                    </Card.Text>
                                                </Card.Body>
                                            </Card>
                                        </Link>
                                    </Col>
                                )
                            })
                        )
                    }
                </Row>
            </Container>
        </>
    )
}

export default ContentIntroduction;