import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap'
import logo from '../images/logo8.png'

function Header() {
    return (
        <header>
            <Container className="Header mx-auto">
                <Row>
                    <Col md="auto">
                        <Image src={logo} className='Header-img-logo' rounded />
                    </Col>
                    <Col className='d-flex align-items-end'>
                        <h1>アルバイトの収入と日々の支出を登録して収支のバランスを見直すアルバイト給料計算アプリ、アルバラン</h1>
                    </Col>
                </Row>
            </Container>
        </header>
    )
}

export default Header;