import React, { useState, useRef } from 'react';
import { useEffect } from 'react';
import { Navbar, Nav, Container } from 'react-bootstrap';
import logo from '../images/logo.png'
import { useDispatch, useSelector } from 'react-redux';
import { pageChanged } from '../redux/pages/actions'
import { useLocation } from 'react-router-dom'

function Navigation() {

    const location = useLocation();
    let currentPagePath = location.pathname;
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);
    //console.log(selector.pages);
    console.log('notifications = '+selector.notifications);
    console.log(selector.notifications);
    console.log("現在のurl = " + currentPagePath);
    const myRef = useRef();
    let pageState = selector.pages;
    console.log('Navigationから呼ばれたpageState = '+pageState);
    console.log(pageState);


    //グローバルナビゲーションでクリックしたリンクのテキストを選択状態にする。
    function navTextStyleChange(pageStateObj) {
        console.log('navTextStyleChangeが呼ばれた');

        let htmlObj = null;
        htmlObj = myRef.current.children;
        //console.log(htmlObj);
        Object.keys(htmlObj).forEach(item => {
            //console.log('key='+item + " value="+htmlObj[item].className)
            if (item == pageStateObj.currentPageNum) {
                htmlObj[item].className = 'nav-link active';
                //htmlObj[item].style = 'color : #ffffff!important';
                //htmlObj[item].style.color = '#ffffff';
                htmlObj[item].setAttribute('style', 'color : #ffffff!important');
            } else {
                htmlObj[item].className = 'nav-link';
            }
        });
        //現在表示しているページ情報をstoreに保存
        dispatch(pageChanged(pageStateObj));
    }

    //ページロード時の処理
    useEffect(() => {
        console.log('NavigationのuseEffectが呼ばれた');
        //ページロード完了後でないとmyRefが使えないので処理をuseEffect内に書く。
        console.log('currentPagePath = ' + currentPagePath)
        //URLが変わった段階で現在のページ情報をstoreに保存する
        switch (currentPagePath) {
            case '/'://トップページ
                //navTextStyleChange(0);
                pageState = { currentPageNum: 0, pageTitleName: 'トップ', pageUrl: '/' };
                break;
            case '/service'://利用規約
                //navTextStyleChange(1);
                pageState = { currentPageNum: 1, pageTitleName: '利用規約', pageUrl: '/service' };
                break;
            case '/privacy'://プライバシーポリシー
                //navTextStyleChange(2);
                pageState = { currentPageNum: 2, pageTitleName: 'プライバシーポリシー', pageUrl: '/privacy' };
                break;
            case '/history'://更新履歴
                //navTextStyleChange(3);
                pageState = { currentPageNum: 3, pageTitleName: '更新履歴', pageUrl: '/history' };
                break;
            case '/contact'://お問合せ
                //navTextStyleChange(4);
                pageState = { currentPageNum: 4, pageTitleName: 'お問合せ', pageUrl: '/contact' };
                break;
            default:
        }
        navTextStyleChange(pageState);
    
    }, [pageState.currentPageNum])

    // const [elementNum, setElementNum] = useState(0);
    // let nowClickedLinkNum = 0;

    // function Test() {
    //     let htmlObj = null;
    //     console.log("これはテストです")
    //     console.log(myRef.current.children.length);
    //     console.log('myRef.current.children=' + myRef.current.children);
    //     console.log(myRef.current.children[0]);
    //     myRef.current.children[3].className = 'nav-link active';
    //     console.log(myRef.current.children[0].className);
    //     console.log(myRef.current.children[3].className);
    //     htmlObj = myRef.current.children;
    //     Object.keys(htmlObj).forEach(item => { console.log('key=' + item + " value=" + htmlObj[item].className) });
    // }

    return (
        <Navbar className="Navigation" expand="sm">
            <Container>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav ref={myRef}>
                        <Nav.Link className="NavLink" href="/">トップ</Nav.Link>
                        <Nav.Link className="NavLink" href="/service">利用規約</Nav.Link>
                        <Nav.Link className="NavLink" href="/privacy">プライバシーポリシー</Nav.Link>
                        <Nav.Link className="NavLink" href="/history">更新履歴</Nav.Link>
                        <Nav.Link className="NavLink" href="/contact">お問合せ</Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}

export default Navigation;