import React, { useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap'
import jsonData from '../data/introduction.json';
import * as Constants from '../constants'

function ContentIntroductionDetail({ id }) {

    const data = jsonData.introductions;
    console.log("ContentIntroductionDetail内のid=" + id);
    let title;
    let summary;
    let dataDetails;
    data.map((item, index) => {
        if (item.number == id) {
            title = item.title;
            document.title = title;
            summary = item.summary;
            dataDetails = item.content_list;
        }
    })

    useEffect(() => {
        window.scroll(0, 0);
    }, [id])

    console.log("dataDetails=" + dataDetails)

    return (
        <>
            <Container className='ContentIntroductionDetail'>
                <Row className='ContentIntroductionDetailTitle'>
                    <h2>{title}</h2>
                </Row>
                <Row className='ContentIntroductionDetailSummary'>
                    <p>{summary}</p>
                </Row>
                <Row>
                    {
                        dataDetails && (
                            dataDetails.map((item, index) => {
                                return (
                                    <Container className='ContentIntroductionDetailContent'>
                                        <Row className='ContentIntroductionDetailContentTitle'>
                                            <Col><h3>{item.number}. {item.title}</h3></Col>
                                        </Row>
                                        <Row className='ContentIntroductionDetailContentData' key={index}>
                                            <Col sm><img src={Constants.PUBLIC_IMG_INTRODUCTION_PATH + item.img_url} /></Col>
                                            <Col sm><p>{item.content}</p></Col>
                                        </Row>
                                    </Container>
                                )
                            })
                        )
                    }
                </Row>
            </Container>
        </>
    )
}

export default ContentIntroductionDetail;