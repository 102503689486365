//プライバシーポリシー
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import PrivacyPolicyDisplay from '../../components/PrivacyPolicyDisplay'

function PrivacyPolicy() {
    document.title = "プライバシーポリシー"
    return (
        <Container>
            <Row>
                <Col>
                    <PrivacyPolicyDisplay />
                </Col>
            </Row>
        </Container>
    )
}

export default PrivacyPolicy;