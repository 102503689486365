import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import * as Constants from '../constants'
import { Link } from "react-router-dom";

function PrivacyPolicyDisplay() {

    const [jsonData, setJsonObj] = useState('');
    //const [loadState, setLoadState] = useState(false);

    useEffect(() => {
        //console.log('before json = '+ json);
        getPrivacyPolicyFromApi();
        console.log('jsonData = ' + jsonData);
    }, [])

    const data = jsonData;
    const dataSection = jsonData.section;

    console.log('jsonData = ' + jsonData);
    console.log('dataSection = ' + dataSection)
    return (
        <>
            <Container className='PrivacyPolicyDisplay'>
                {
                    data && (
                        <section>
                            <h2>{data.title}</h2>
                            <p>{data.premise}</p>
                        </section>
                    )
                }

                {
                    dataSection && (
                        dataSection.map((item, index) => {
                            return (
                                <section key={index}>
                                    <h3>{item.number}. {item.title}</h3>
                                    {
                                        item.content && (
                                            <p>{item.content}</p>
                                        )
                                    }
                                    {
                                        item.sub_section && (
                                            item.sub_section.map((subItem, subIndex) => {
                                                return (
                                                    subItem.data_list && (
                                                        <section key={subIndex}>
                                                            {
                                                                item.sub_section.length == 1 ?

                                                                    //sub_sectionの内容が1つしかない時は番号を付けない

                                                                    <h4>{subItem.title}</h4>
                                                                    :

                                                                    //sub_sectionの内容が2以上の時は番号を付ける

                                                                    <h4>{subItem.number}. {subItem.title}</h4>
                                                            }
                                                            {
                                                                subItem.content && (
                                                                    <p style={{marginLeft: '25px'}}>{subItem.content}</p>
                                                                )
                                                            }

                                                            <ul>
                                                                {
                                                                    subItem.data_list.map((dataListItem, dataListIndex) => {
                                                                        return (
                                                                            <li key={dataListIndex}>{dataListItem.content}</li>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                        </section>
                                                    )
                                                )
                                            })
                                        )
                                    }
                                </section>
                            )
                        })
                    )
                }
                {
                    data && (
                        <>
                            {
                                data.external_service && (
                                    <section>
                                        <h3>{data.external_service.title}</h3>
                                        <p>{data.external_service.content}</p>
                                        <ul>
                                            {
                                                data.external_service.url_list && (
                                                    data.external_service.url_list.map((urlListItem, urlListIndex) => {
                                                        return (
                                                            <li>
                                                                <section key={urlListIndex}>
                                                                    <span>{urlListItem.title} : </span><a href={urlListItem.url} target='_blank'>{urlListItem.url}</a>
                                                                </section>
                                                            </li>
                                                        )
                                                    })
                                                )
                                            }
                                        </ul>
                                    </section>
                                )
                            }
                            <section>
                                <h3>制定日・改定日</h3>
                                <p>制定日：{data.date_of_enforcement}</p>
                                <p>改定日：</p>
                                {
                                    data.date_of_revision_list && (
                                        <ul>
                                            {
                                                data.date_of_revision_list.map((revisionListItem, revisionListIndex) => {
                                                    return (
                                                        <li key={revisionListIndex}>{revisionListItem.date}</li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    )
                                }
                            </section>
                        </>
                    )
                }
            </Container>
        </>
    )

    async function getPrivacyPolicyFromApi() {

        try {
            let response = await fetch(Constants.PRIVACY_POLICY_JSON_URL);
            //let response = await fetch(jsonUrl);
            console.log('URL = ' + Constants.PRIVACY_POLICY_JSON_URL);
            let responseJson = await response.json();
            setJsonObj(responseJson);
            //console.log('getMoviesFromApi()内のjson = ' + json);

        } catch (error) {
            console.error('jsonエラー : ' + error);
        }

    }

}

export default PrivacyPolicyDisplay;