import logo from './logo.svg';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap'
import Test from './templates/contents/Test'
import Navigation from './components/Navigation'
import Header from './components/Header';
import Router from './components/Router';
import Footer from './components/Footer';
//import { getDataFromFirestore } from "./libraries/firebase";
import * as Constants from './constants'

function App() {
  //console.log('Appが呼び出された');
  //let snapshot = await getDataFromFirestore(Constants.NOTIFICATIONS);
  //console.log('snapshot = '+snapshot);
  /*
  snapshot.data.forEach(doc => {
    console.log(doc.id, '=>', doc.data());
  });
*/
  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Navigation />
        <Router />
        <Footer />
      </div>
    </BrowserRouter>
  );
  
}

export default App;
