import React, { useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap'
import * as Constants from '../constants'

function ContactForm() {

    return (
        <Container className='ContactForm'>
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLSfpoi3cr2mn9KsJLlTTRNq6sVFQINPC0aZD8y-ziXTi9EXYjQ/viewform?embedded=true" width="100%" height="1000" frameborder="0" marginheight="0" marginwidth="0" scrolling="yes">読み込んでいます…</iframe>
        </Container>
    )
}

export default ContactForm;